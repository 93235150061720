<template>
  <div class="terminals">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfigRef"
      :pageTitle="$t('general.router-terminal-status')"
      :showBackBtn="true"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @pageInfoChange="pageInfoChangeAction"
    ></page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/terminal.status.search'
import { contentTableConfig } from './config/terminal.status.table'
// import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import _ from 'lodash'
// const store = useStore()
const routes = useRoute()
const id = routes.params.id
const pageInitialQuery = { terminalId: id }
// 表格基础配置项
const tableOptions = {
  moduleName: 'terminal', // 所属的模块
  pageName: 'terminalsStatus', // 所属的页面
  contentTableConfig,
  isShowCreateSlot: false,
  pageInitialQuery,
  pageAction: 'terminal/getTerminalStatusData', // 获取表格的Action
  pageCountGetter: 'terminal/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'terminal/pageListData' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

const searchFormConfigRef = computed(() => {
  return searchFormConfig
})

const handleQueryItem = (items) => {
  const array = []
  const obj = _.cloneDeep(pageInitialQuery)
  Object.keys(items).forEach((key) => {
    if (items[key] !== '') array.push(key)
  })
  array.forEach((key) => {
    obj[key] = items[key]
  })
  return obj
}
const handleQueryClick = (queryInfo) => {
  const hasQueryInfo = !!Object.values(queryInfo).find((item) => item !== '')
  queryInfo = hasQueryInfo ? handleQueryItem(queryInfo) : pageInitialQuery
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData(pageInitialQuery)
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
