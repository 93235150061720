export const searchFormConfig = {
  formItems: [
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
