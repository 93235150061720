export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'terminalId',
      label: 'terminal.id'
    },
    {
      prop: 'sn',
      label: 'terminal.sn'
    },
    {
      prop: 'batteryInfo',
      label: 'terminal.battery-info'
    },
    {
      prop: 'bootTime',
      label: 'terminal.boot-time'
    },
    {
      prop: 'networkInfo',
      label: 'terminal.network-info'
    },
    {
      prop: 'memoryInfo',
      label: 'terminal.memory-info'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'general.creation-date'
    }
  ],
  showIndexColumn: true
}
